<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_186_7881"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_186_7881)">
			<path
				d="M12 15.0384L6.34616 9.38464L7.39999 8.33081L12 12.9308L16.6 8.33081L17.6538 9.38464L12 15.0384Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
